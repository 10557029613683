<template>
  <div class="created-room-welcome-header">
    <div class="mt-2" v-if="roomJoinRule == 'public'">
      <i18n path="room_welcome.join_channel" tag="span">
        <template v-slot:link>
          <div style="position:relative;display:inline-block">
            <a @click.stop="copyPublicLink" :href="publicRoomLink" class="text-break">{{ publicRoomLink }}</a>
            <v-btn v-if="publicRoomLinkCopied" id="btn-copy-room-link" color="#444444" depressed
              style="position:absolute;left:0;top:0" class="filled-button link-copied-in-place">{{
      $t("room_info.link_copied") }}</v-btn>
          </div>
        </template>
      </i18n>
    </div>
    <div class="mt-2" v-else-if="roomJoinRule == 'invite'">
      {{ $t("room_welcome.join_invite") }}
    </div>
    <div class="mt-2" v-if="roomMessageRetention() > 0">
      <i18n path="room_welcome.info_retention" tag="span">
        <template v-slot:time>
          <b>{{ messageRetentionDisplay }}</b>
        </template>
      </i18n>&nbsp;
      <a href="#" text @click.prevent="showMessageRetentionDialog = true" style="white-space: pre-line;">{{ $t("room_welcome.change") }}</a>
    </div>
    <div class="text-end">
      <v-btn id="btn-got-it" text @click.stop="$emit('close')" class="text-transform-0">
        {{ $t("room_welcome.got_it") }}
      </v-btn>
    </div>

    <MessageRetentionDialog :show="showMessageRetentionDialog" :room="room" @close="showMessageRetentionDialog = false"
      v-on:message-retention-update="onMessageRetention" />
  </div>
</template>

<script>
import MessageRetentionDialog from '../MessageRetentionDialog.vue';
import roomInfoMixin from "../roomInfoMixin";

export default {
  name: "WelcomeHeaderChannel",
  mixins: [roomInfoMixin],
  components: {
    MessageRetentionDialog
  },
  computed: {
  },
  data() {
    return {
      publicRoomLinkCopied: false,
      showMessageRetentionDialog: false,
    }
  },
  methods: {
    onMessageRetention(ignoredretention) {
      this.updateMessageRetention();
    },
    copyPublicLink() {
      const self = this;
      this.$copyText(this.publicRoomLink).then(
        function (ignored) {
          // Success!
          self.publicRoomLinkCopied = true;
          setInterval(() => {
            // Hide again
            self.publicRoomLinkCopied = false;
          }, 3000);
        },
        function (e) {
          console.log(e);
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>