<template>
  <div class="created-room-welcome-header">
    <div style="white-space: pre-line;">
    {{ $t('room_welcome.info_auto_join',{room: room ? room.name : "",you: $matrix.currentUserDisplayName}) }}
      <a href="#" text @click.prevent="viewProfile">
        {{ $t("room_welcome.change") }}
      </a>
  </div>
    <div class="mt-2" v-if="roomMessageRetention() > 0">
      <i18n path="room_welcome.info_retention_user" tag="span">
        <template v-slot:time>
          <b>{{ messageRetentionDisplay }}</b>
        </template>
      </i18n>
    </div>
  </div>
</template>

<script>
import roomInfoMixin from "../roomInfoMixin";

export default {
  name: "WelcomeHeaderChannelUser",
  mixins: [roomInfoMixin],
  components: {
  },
  computed: {
  },
  data() {
    return {
    }
  },
  methods: {
    viewProfile() {
      this.$navigation.push({ name: "Profile" }, 1);
    },
    onMessageRetention(ignoredretention) {
      this.updateMessageRetention();
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>